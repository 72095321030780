import "./App.css";
import Layout from "./Layout";
import UserAuthForm from "./Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { TriggerProvider } from "./triggerContext";

function App() {

  return (
    <TriggerProvider>
      <div className="relative bg-[#FCFCFC] h-[100%]">
        <Routes>
          <Route path="/" element={<UserAuthForm />} />
          <Route path="/login" element={<UserAuthForm />} />
          <Route path="/*" element={<Layout />} />
        </Routes>
      </div>
    </TriggerProvider>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
