import { Button } from "../components/ui/button";
import { CirclePlus } from "lucide-react";
import { PersonIcon } from "@radix-ui/react-icons";
import { useSidebar } from "@/components/ui/sidebar";

export default function Members() {
  const { isMobile } = useSidebar();
  const userDetails = [
    {
      username: "John Doe",
      email: "johndoe@gmail.com",
      role: "admin",
      timezone: "Indian Standard Time",
    },
    {
      username: "Jane Smith",
      email: "janesmith@yahoo.com",
      role: "editor",
      timezone: "Eastern Standard Time",
    },
    {
      username: "Michael Brown",
      email: "michael.brown@outlook.com",
      role: "viewer",
      timezone: "Pacific Standard Time",
    },
    {
      username: "Emily Davis",
      email: "emily.davis@gmail.com",
      role: "member",
      timezone: "Greenwich Mean Time",
    },
  ];

  return (
    <div className="p-[2%] w-[70vw] h-auto flex flex-col ">
      <div
        className={`flex  justify-between  p-5 ${
          isMobile ? "flex-col gap-2" : "flex-row items-center"
        }`}
      >
        <div className="flex flex-col items-left justify-start text-left">
          <h1 className="text-lg font-bold text-left">Manage Your Members</h1>
          <h6>2 active members</h6>
        </div>
        <Button className="w-fit">
          <CirclePlus /> Add Member
        </Button>
      </div>
      <div>
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="p-3  border-b border-gray-300 text-center">
                Users
              </th>
              {!isMobile && (
                <>
                  <th className="p-3 border-b border-gray-300 text-center">
                    Role
                  </th>
                  <th className="p-3 border-b border-gray-300 text-center">
                    Timezone
                  </th>
                  <th className="p-3 border-b border-gray-300 text-center"></th>
                </>
              )}
              <th className="p-3 border-b border-gray-300 text-center"></th>
            </tr>
          </thead>
          <tbody>
            {userDetails.map((user, index) => (
              <tr key={index} className="bg-white ">
                <td className=" p-3 border-b border-gray-300">
                  <div className="flex flex-row items-center gap-3">
                    {!isMobile && (
                      <PersonIcon
                        className="w-8 h-8 border-2 rounded-full border-black cursor-pointer"
                        aria-hidden="true"
                      />
                    )}
                    <div>
                      <p className="font-medium text-left whitespace-nowrap overflow-hidden text-ellipsis">
                        {user.username}
                      </p>
                      <p className="text-xs text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis">
                        {user.email}
                      </p>
                    </div>
                  </div>
                </td>
                {!isMobile && (
                  <>
                    <td className="p-3 border-b border-gray-300">
                      {user.role}
                    </td>
                    <td className="p-3 border-b border-gray-300">
                      {user.timezone}
                    </td>
                    <td className="p-3 border-b border-gray-300 text-center text-blue-400 cursor-pointer">
                      Edit
                    </td>
                  </>
                )}

                <td
                  className={`p-3 border-b border-gray-300 text-center cursor-pointer ${
                    isMobile ? "relative " : ""
                  } `}
                >
                  {isMobile ? (
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z"
                        fill="currentColor"
                        fillRule="evenodd"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.625 7.5C3.625 8.12132 3.12132 8.625 2.5 8.625C1.87868 8.625 1.375 8.12132 1.375 7.5C1.375 6.87868 1.87868 6.375 2.5 6.375C3.12132 6.375 3.625 6.87868 3.625 7.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM12.5 8.625C13.1213 8.625 13.625 8.12132 13.625 7.5C13.625 6.87868 13.1213 6.375 12.5 6.375C11.8787 6.375 11.375 6.87868 11.375 7.5C11.375 8.12132 11.8787 8.625 12.5 8.625Z"
                        fill="currentColor"
                        fillRule="evenodd"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
