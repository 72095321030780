"use client";

import { ChevronRight, type LucideIcon } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar";
import { useSidebar } from "@/components/ui/sidebar";

export function NavMain({
  items,
}: {
  items: {
    title: string;
    url: string;
    icon?: any;
    isActive?: boolean;
    items?: {
      title: string;
      url: string;
    }[];
  }[];
}) {
  const location = useLocation();

  const { isMobile, toggleSidebar } = useSidebar();

  useEffect(() => {
    // console.log("Current path:", location.pathname);
  }, [location.pathname]);

  const handleItemClick = (url: string) => {
    if (isMobile) {
      toggleSidebar();
    }
  };

  return (
    <SidebarGroup>
      <SidebarMenu>
        {items.map((item, index) => (
          //   <Collapsible key={item.title} defaultOpen={item.isActive}>
          // <Tooltip key={item.title}>
          //   <TooltipTrigger asChild>
          <SidebarMenuItem
            key={index}
            className={
              item.url === location.pathname ? "text-black font-bold " : ""
            }
          >
            <Link to={item.url} onClick={() => handleItemClick(item.url)}>
              <SidebarMenuButton
                className="flex items-center  text-xl  my-2"
                tooltip={{
                  children: item.title,
                  side: "right",
                  align: "center",
                }}
              >
                {typeof item.icon === "string" ? (
                  <span>{item.icon} </span> // Wrap emoji in span
                ) : (
                  <item.icon /> // Render component if it's an icon component
                )}
                <span>{item.title}</span>
              </SidebarMenuButton>
            </Link>
          </SidebarMenuItem>
          // </TooltipTrigger>

          // <TooltipContent side="right">
          // <span>{item.title}</span>
          // </TooltipContent>
          // </Tooltip>
          //     {item.items && (
          //       <CollapsibleContent>
          //         <SidebarMenuSub>
          //           {item.items.map((subItem) => (
          //             <SidebarMenuSubItem key={subItem.title}>
          //               <SidebarMenuSubButton asChild>
          //                 <Link to={subItem.url}>
          //                   <span>{subItem.title}</span>
          //                 </Link>
          //               </SidebarMenuSubButton>
          //             </SidebarMenuSubItem>
          //           ))}
          //         </SidebarMenuSub>
          //       </CollapsibleContent>
          //     )}
          //   </Collapsible>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
