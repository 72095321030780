// triggerContext.js
import { createContext, useContext, useState, ReactNode } from "react";
import { Trigger } from "./types";

interface TriggerContextType {
  trigger: Trigger | null;
  setTrigger: (trigger: Trigger) => void;
}

const TriggerContext = createContext<TriggerContextType | undefined>(undefined);

export function TriggerProvider({ children }: { children: ReactNode }) {
  const [trigger, setTrigger] = useState<Trigger | null>(null);

  return (
    <TriggerContext.Provider value={{ trigger, setTrigger }}>
      {children}
    </TriggerContext.Provider>
  );
}

export function useTrigger() {
  const context = useContext(TriggerContext);
  if (!context) {
    throw new Error("useTrigger must be used within a TriggerProvider");
  }
  return context;
}
