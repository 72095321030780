import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useEffect, useState, useMemo, useRef, LegacyRef } from "react";
import { CheckIcon, ChevronsUpDown, CalendarIcon } from "lucide-react";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import MultipleSelector from "../components/ui/multi-selector";
import { triggers, timeZones, items, OPTIONS } from "../myTriggers";
import createTriggerApi from "./createTriggerApi";
import { Trigger } from "../types";
import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectLabel,
  SelectGroup,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useSidebar } from "@/components/ui/sidebar";

// Validation schema
const formSchema = z.object({
  trigger_type: z.string(),
  trigger_name: z.string().min(1, { message: "Trigger name is required" }),

  trigger_desc: z
    .string()
    .min(5, { message: "Should be at least 5 characters" })
    .default(""),

  trigger_participants: z
    .array(z.string())
    .nonempty({ message: "At least one channel or user must be selected" }),

  trigger_period: z.string().min(1, { message: "Please select a period" }),

  trigger_days: z
    .array(z.string())
    .nonempty({ message: "At least one schedule option must be selected" }),

  trigger_time: z.object({
    time: z
      .string()
      .regex(/^\d{1,2}:\d{2} (AM|PM)$/i, { message: "Invalid time format" }), // Time format: 12:00 AM or 12:00 PM
    timezone: z.string().min(1, { message: "Timezone is required" }), // Ensure the timezone is a non-empty string
  }),

  trigger_icon: z.string(),
});

interface MultipleSelectorRef {
  reset: () => void;
}

export function ReminderForm({ trigger }: { trigger: Trigger | null }) {
  const { isMobile } = useSidebar();
  const navigate = useNavigate();
  // console.log("trigger", trigger);

  if (
    !trigger ||
    (Object.keys(trigger).length === 0 && trigger.constructor === Object)
  ) {
    trigger =
      triggers.find((trigger) => trigger.trigger_hash === "reminder") || null;
  }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      trigger_type: "reminder",
      trigger_icon: trigger?.trigger_icon,
      trigger_name: trigger?.trigger_name || "",
      trigger_participants: [],
      trigger_desc: "",
      trigger_period: "0",
      trigger_days: [],
      trigger_time: { time: "", timezone: "" },
    },
  });

  const [date, setDate] = useState<Date>();
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [isAM, setIsAM] = useState(true);
  const [timeZone, setTimeZone] = useState("");
  const [participants, setParticipants] = useState<string[]>([]);

  const multipleSelectorRef = useRef<MultipleSelectorRef>(null);
  const triggerButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (trigger?.trigger_participants) {
      const selectedParticipants = OPTIONS.filter((option) =>
        trigger?.trigger_participants?.includes(option.value)
      ).map((option) => option.value);
      setParticipants(selectedParticipants);
      form.setValue(
        "trigger_participants",
        selectedParticipants as [string, ...string[]]
      );
      console.log("parti", form.getValues("trigger_participants"));
    }
    if (trigger?.content) {
      form.setValue("trigger_desc", trigger.content);
    }
    if (trigger?.trigger_days) {
      form.setValue(
        "trigger_days",
        trigger.trigger_days as [string, ...string[]]
      );
    }
    if (trigger?.trigger_time) {
      const [time, format] = trigger.trigger_time.split(" ");
      const [hr, min] = time.split(":");

      form.setValue("trigger_time", {
        time: `${hr}:${min} ${format}`,
        timezone: trigger.trigger_timezone ?? "UTC", // Fallback to "UTC" or another default value,
      });

      setSelectedHour(hr);
      setSelectedMinute(min);
      setIsAM(format === "AM");
      setTimeZone(trigger.trigger_timezone ?? "UTC");
    }
  }, [trigger]);

  const filteredTimeZones: Record<string, { value: string; label: string }[]> =
    useMemo(() => {
      return Object.entries(timeZones).reduce((acc: any, [region, zones]) => {
        const filteredZones = zones.filter((zone) =>
          zone.label.toLowerCase().includes(timeZone.toLowerCase())
        );
        if (filteredZones.length > 0) acc[region] = filteredZones;
        return acc;
      }, {} as typeof timeZones);
    }, [timeZone]);

  const mappedOptions = OPTIONS.map((channel) => ({
    label: channel.label,
    value: channel.value,
    group: channel.group,
  }));

  console.log(form.formState.errors);
  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    // console.log("data", data);
    if (trigger?.trigger_id) {
      console.log("Trigger already exists", trigger);
      // data.trigger_id = trigger.trigger_id;
    }
    try {
      const response = await createTriggerApi(data);

      console.log("Trigger created successfully:", response);

      // Reset form only if API call succeeds
      form.reset({
        trigger_icon: trigger?.trigger_icon,
        trigger_type: "reminder",
        trigger_name: trigger?.trigger_name || "",
        trigger_participants: [""],
        trigger_desc: "",
        trigger_period: "0",
        trigger_days: [],
        trigger_time: { time: "", timezone: "" },
      });
      form.setValue("trigger_desc", "");
      setParticipants([]);
      setSelectedHour("");
      setSelectedMinute("");
      setIsAM(true);

      if (multipleSelectorRef.current) {
        multipleSelectorRef.current.reset();
      }

      if (!trigger?.trigger_id && triggerButtonRef.current) {
        triggerButtonRef.current.click();
      } else {
        alert("Trigger updated successfully");
      navigate("/home")
      }
    } catch (error: any) {
      console.error("Failed to create trigger:", error.message);
      // Optionally display an error message to the user
    }
  };

  const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const formattedTime =
    selectedHour && selectedMinute
      ? `${selectedHour}:${selectedMinute} ${isAM ? "AM" : "PM"}`
      : "Select time";

  const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
  const minutes = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0")
  );

  const [open, setOpen] = useState(false);
  function onClose() {
    setOpen(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-3 w-[90%] p-[30px]"
      >
        <div className="flex justify-evenly text-2xl font-semibold">
          {trigger?.trigger_name}
        </div>

        {/* Trigger Name */}
        <FormField
          control={form.control}
          name="trigger_name"
          render={({ field }) => (
            <FormItem>
              <div
                className={`flex ${
                  isMobile
                    ? "flex-col items-left gap-2"
                    : " flex-row justify-evenly"
                }`}
              >
                <FormLabel>Trigger Name</FormLabel>
                <FormControl>
                  <Input
                    placeholder=""
                    {...field}
                    defaultValue={trigger?.trigger_name}
                    className={`${
                      isMobile ? "max-w-[400px] w-[100%]" : "w-[400px]"
                    }`}
                  />
                </FormControl>
              </div>
              <div className="flex flex-row items-center justify-center">
                <p className={`${isMobile ? "w-[0%] " : "w-[30%] "}`}></p>
                <p className="text-[0.8rem] font-medium text-destructive">
                  {form.formState.errors?.trigger_name?.message}
                </p>
              </div>
            </FormItem>
          )}
        />

        {/* Send Reminder To */}
        <FormField
          control={form.control}
          name="trigger_participants"
          render={({ field }) => (
            <FormItem>
              <div
                className={`flex ${
                  isMobile
                    ? "flex-col items-left gap-2"
                    : " flex-row justify-evenly"
                }`}
              >
                <FormLabel>Send Reminder to</FormLabel>
                <FormControl>
                  <MultipleSelector
                    className={`${
                      isMobile ? "max-w-[400px] w-[100%]" : "w-[400px]"
                    }`}
                    ref={multipleSelectorRef as LegacyRef<any>}
                    defaultOptions={mappedOptions}
                    placeholder="Select Users/Channels you like"
                    hidePlaceholderWhenSelected={true}
                    value={
                      mappedOptions.filter((option) =>
                        participants.includes(option.value)
                      ) || []
                    }
                    onChange={(selectedOption) => {
                      const participants = selectedOption.map(
                        (option) => option.value
                      );
                      console.log("Participants:", participants);
                      setParticipants(participants);
                      field.onChange(participants);
                    }}
                    options={mappedOptions}
                    emptyIndicator={
                      <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                        no results found.
                      </p>
                    }
                    groupBy="group"
                  />
                </FormControl>
              </div>
              <div className="flex flex-row items-center justify-center">
                <p className={`${isMobile ? "w-[0%] " : "w-[30%] "}`}></p>
                <p className="text-[0.8rem] font-medium text-destructive">
                  {form.formState.errors?.trigger_participants?.message}
                </p>
              </div>
            </FormItem>
          )}
        />

        {/* Reminder Description */}
        <FormField
          control={form.control}
          name="trigger_desc"
          render={({ field }) => (
            <FormItem>
              <div
                className={`flex ${
                  isMobile
                    ? "flex-col items-left gap-2"
                    : " flex-row justify-evenly"
                }`}
              >
                <FormLabel>Reminder to Send</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Type your message here"
                    defaultValue={trigger?.content || ""}
                    {...field}
                    onChange={(e) => {
                      form.setValue("trigger_desc", e.target.value);
                    }}
                    className={`${
                      isMobile ? "max-w-[400px] w-[100%]" : "w-[400px]"
                    }`}
                  />
                </FormControl>
              </div>
              <div className="flex flex-row items-center justify-center">
                <p className={`${isMobile ? "w-[0%] " : "w-[30%] "}`}></p>
                <p className="text-[0.8rem] font-medium text-destructive">
                  {form.formState.errors?.trigger_desc?.message}
                </p>
              </div>
            </FormItem>
          )}
        />

        {/* Trigger peroid */}
        <FormField
          control={form.control}
          name="trigger_period"
          render={({ field }) => (
            <FormItem>
              <div
                className={`flex ${
                  isMobile
                    ? "flex-col items-left gap-2"
                    : " flex-row justify-evenly"
                }`}
              >
                <FormLabel>How Often?</FormLabel>
                <FormControl>
                  <div
                    className={`flex flex-row space-x-2  mx-1 ${
                      isMobile ? "max-w-[400px] w-[100%]" : "w-[400px]"
                    }`}
                  >
                    <Select
                      defaultValue={trigger?.trigger_frequency || ""}
                      value={field.value || ""}
                      onValueChange={(value) => {
                        setDate(addDays(new Date(), parseInt(value)));
                        field.onChange(value.toString());
                      }}
                    >
                      <SelectTrigger className="text-sm">
                        <SelectValue placeholder="Period" />
                      </SelectTrigger>
                      <SelectContent position="popper">
                        <SelectItem value="0">Daily</SelectItem>
                        <SelectItem value="1">Weekly</SelectItem>
                        <SelectItem value="2">Bi-Weekly</SelectItem>
                        <SelectItem value="3">Monthly</SelectItem>
                        <SelectItem value="4">Only once</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </FormControl>
              </div>
              <div className="flex flex-row items-center justify-center">
                <p className={`${isMobile ? "w-[0%] " : "w-[30%] "}`}></p>
                <p className="text-[0.8rem] font-medium text-destructive">
                  {form.formState.errors?.trigger_period?.message}
                </p>
              </div>
            </FormItem>
          )}
        />

        {/* Trigger Schedule (Checkboxes) */}
        <FormField
          control={form.control}
          name="trigger_days"
          render={({ field }) => (
            <FormItem>
              <div
                className={`flex ${
                  isMobile
                    ? "flex-col items-left gap-2"
                    : " flex-row justify-evenly"
                }`}
              >
                <FormLabel>On which days?</FormLabel>
                <FormControl>
                  <div
                    className={`flex flex-nowrap ${
                      isMobile ? "max-w-[400px] w-[100%]" : "w-[400px]"
                    } justify-evenly`}
                  >
                    {items.map((item) => (
                      <div key={item.id} className="relative mb-2 mx-1">
                        <input
                          type="checkbox"
                          id={item.id}
                          name="trigger_days"
                          value={item.label}
                          className="absolute opacity-0"
                          checked={field.value.includes(item.label)}
                          onChange={() => {
                            const updatedDays = field.value.includes(item.label)
                              ? field.value.filter(
                                  (value) => value !== item.label
                                )
                              : [...field.value, item.label];
                            field.onChange(updatedDays);
                          }}
                        />
                        <label
                          htmlFor={item.id}
                          className={`inline-block p-2 cursor-pointer border border-slate-400 transition-colors duration-200 rounded-full w-8 h-8 flex items-center justify-center
                      ${
                        field.value.includes(item.label)
                          ? "border-black bg-[#000000] text-[#ffffff]"
                          : ""
                      }`}
                        >
                          {item.label[0]}
                        </label>
                      </div>
                    ))}
                  </div>
                </FormControl>
              </div>
              <div className="flex flex-row items-center justify-center">
                <p className={`${isMobile ? "w-[0%] " : "w-[30%] "}`}></p>
                <p className="text-[0.8rem] font-medium text-destructive">
                  {form.formState.errors?.trigger_days?.message}
                </p>
              </div>
            </FormItem>
          )}
        />

        {/* Trigger Time and Timezone */}
        <FormField
          control={form.control}
          name="trigger_time"
          render={({ field }) => {
            const { onChange, value } = field;

            return (
              <FormItem>
                <div
                  className={`flex ${
                    isMobile
                      ? "flex-col items-left gap-2"
                      : " flex-row justify-evenly"
                  }`}
                >
                  <FormLabel>Trigger time</FormLabel>
                  <FormControl>
                    <div
                      className={`flex ${
                        isMobile ? "flex-col gap-2" : "flex-row space-4"
                      }`}
                    >
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            className={`justify-between font-normal ${
                              isMobile ? "max-w-[400px] w-[100%]" : "w-[200px]"
                            }`}
                          >
                            {formattedTime}
                            <ChevronsUpDown className="ml-2 h-4 w-4" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[300px] p-4">
                          <div className="flex space-x-4">
                            {/* Select Hour */}
                            <Select
                              value={selectedHour}
                              onValueChange={(value) => {
                                setSelectedHour(value);
                                const newTime = `${value}:${
                                  selectedMinute || "00"
                                } ${isAM ? "AM" : "PM"}`;
                                // Ensure both time and timezone are passed to the form
                                onChange({
                                  time: newTime,
                                  timezone: field.value?.timezone || "",
                                });
                              }}
                            >
                              <SelectTrigger>
                                <span>
                                  {selectedHour ? selectedHour : "HH"}
                                </span>
                              </SelectTrigger>
                              <SelectContent>
                                {hours.map((hour) => (
                                  <SelectItem key={hour} value={hour}>
                                    {hour}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>

                            {/* Select Minute */}
                            <Select
                              value={selectedMinute}
                              onValueChange={(value) => {
                                setSelectedMinute(value);
                                const newTime = `${
                                  selectedHour || "00"
                                }:${value} ${isAM ? "AM" : "PM"}`;
                                onChange({
                                  time: newTime,
                                  timezone: field.value?.timezone || "",
                                });
                              }}
                            >
                              <SelectTrigger>
                                <span>
                                  {selectedMinute ? selectedMinute : "MM"}
                                </span>
                              </SelectTrigger>
                              <SelectContent>
                                {minutes.map((minute) => (
                                  <SelectItem key={minute} value={minute}>
                                    {minute}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>

                            {/* AM/PM Toggle */}
                            <Button
                              onClick={() => {
                                setIsAM(!isAM);
                                const newTime = `${selectedHour || "00"}:${
                                  selectedMinute || "00"
                                } ${!isAM ? "AM" : "PM"}`;
                                onChange({
                                  time: newTime,
                                  timezone: field.value?.timezone || "",
                                });
                              }}
                              variant="outline"
                            >
                              {isAM ? "AM" : "PM"}
                            </Button>
                          </div>
                        </PopoverContent>
                      </Popover>

                      {/* Timezone Selector */}
                      <Select
                        value={field.value?.timezone || ""}
                        onValueChange={(timezone) =>
                          onChange({
                            time: field.value?.time,
                            timezone,
                          })
                        }
                      >
                        <SelectTrigger
                          className={`justify-between ${
                            isMobile
                              ? "max-w-[400px] w-[100%]"
                              : "w-[200px] mx-2"
                          }`}
                        >
                          <SelectValue
                            placeholder={timeZone || "Select a timezone"}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <div className="p-2">
                            <Input
                              placeholder="Search time zones..."
                              value={timeZone || ""}
                              onChange={(e) => setTimeZone(e.target.value)}
                              className="w-full mb-2"
                            />
                          </div>
                          {Object.entries(filteredTimeZones).map(
                            ([region, zones]) => (
                              <SelectGroup key={region}>
                                <SelectLabel>{region}</SelectLabel>
                                {zones.map((zone) => (
                                  <SelectItem
                                    key={zone.value}
                                    value={zone.value}
                                  >
                                    {zone.label}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            )
                          )}
                        </SelectContent>
                      </Select>
                    </div>
                  </FormControl>
                </div>

                <div className="flex flex-row items-center justify-center">
                  <p className={`${isMobile ? "w-[0%] " : "w-[30%] "}`}></p>
                  <p className="text-[0.8rem] font-medium text-destructive">
                    {form.formState.errors?.trigger_time?.time?.message}
                    {form.formState.errors?.trigger_time?.time?.message &&
                      form.formState.errors?.trigger_time?.timezone
                        ?.message && <br />}
                    {form.formState.errors?.trigger_time?.timezone?.message}
                  </p>
                </div>
              </FormItem>
            );
          }}
        />

        {/* Submit Button */}
        <div className="flex justify-evenly">
          <div> </div>
          <div>
            <Button type="submit" className="w-[200px] ">
              {trigger?.trigger_id ? "Save Changes" : "Create Trigger"}
            </Button>{" "}
          </div>
        </div>
      </form>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger asChild>
          <button ref={triggerButtonRef} style={{ display: "none" }} />
        </AlertDialogTrigger>

        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="text-xl">Success!</AlertDialogTitle>
            <AlertDialogDescription>
              Your trigger has been successfully{" "}
              {trigger?.trigger_id ? "updated" : "created"}.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <Button onClick={onClose} className="m-2">
              Create Another Trigger
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
              navigate("/home")
              }}
              className="m-2"
            >
              Go to Home
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Form>
  );
}
