"use client";

import * as React from "react";
import { CogIcon, CreditCardIcon, Zap } from "lucide-react";
import { LightningBoltIcon } from "@radix-ui/react-icons";

import { NavMain } from "@/components/ui/nav-main";
import { TeamSwitcher } from "@/components/ui/team-switcher";

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from "@/components/ui/sidebar";

const items = [
  {
    title: "Triggers",
    url: "/home",
    icon: LightningBoltIcon,
  },
  // {
  //   title: "Profile",
  //   url: "/profile",const data = {
  //   icon: UserIcon,
  // },
  {
    title: "Settings",
    url: "/settings/account",
    icon: CogIcon,
  },
  {
    title: "Billing",
    url: "/billing",
    icon: CreditCardIcon,
    // 💲💰
  },
];

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <TeamSwitcher />
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={items} />
        {/* headings */}
        {/* <NavProjects projects={data.projects} /> */}
      </SidebarContent>
      {/* <SidebarFooter>
        <NavUser user={data.user} />
      </SidebarFooter> */}
      <SidebarRail />
    </Sidebar>
  );
}
