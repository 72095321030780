import { Button } from "./components/ui/button";
import { FormItem, FormLabel } from "./components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "./components/ui/select";
import { useState, useEffect } from "react";

interface TimePickerProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
}

export const TimePicker: React.FC<TimePickerProps> = ({
  label,
  value,
  onChange,
  disabled,
}) => {
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("00");
  const [isAM, setIsAM] = useState(true);

  console.log("value", value);
  useEffect(() => {
    if (value) {
      const [time, period] = value.split(" ");
      const [hour, minute] = time.split(":");
      setHour(hour);
      setMinute(minute);
      setIsAM(period === "AM");
    }
  }, [value]);

  // Update the parent component whenever hour, minute, or isAM changes
  useEffect(() => {
    if (hour && minute) {
      onChange(`${hour}:${minute.padStart(2, "0")} ${isAM ? "AM" : "PM"}`);
    }
  }, [hour, minute, isAM, onChange]);

  const formattedTime =
    hour && minute
      ? `${hour}:${minute.padStart(2, "0")} ${isAM ? "AM" : "PM"}`
      : "Select time";

  return (
    <FormItem className="w-[100%] lg:w-[70%]  mb-2">
      <FormLabel className={`py-2  ${disabled ? "text-gray-400" : ""}`}>
        {label}
        {disabled && (
          <span className="text-xs text-gray-400"> (coming soon)</span>
        )}
      </FormLabel>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="w-[100%] flex justify-start"
            disabled={disabled}
          >
            {formattedTime}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <div className="flex space-x-4 w-[100%]">
            <Select value={hour} onValueChange={(val) => setHour(val)}>
              <SelectTrigger>{hour ? hour : "HH"}</SelectTrigger>
              <SelectContent>
                {Array.from({ length: 12 }, (_, i) => (
                  <SelectItem key={i} value={String(i + 1)}>
                    {i + 1}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Select value={minute} onValueChange={(val) => setMinute(val)}>
              <SelectTrigger>{minute ? minute : "MM"}</SelectTrigger>
              <SelectContent>
                {Array.from({ length: 60 }, (_, i) => (
                  <SelectItem key={i} value={String(i).padStart(2, "0")}>
                    {String(i).padStart(2, "0")}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Button variant={"outline"} onClick={() => setIsAM(!isAM)}>
              {isAM ? "AM" : "PM"}
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </FormItem>
  );
};
