import { Button } from "./components/ui/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { triggers } from "./myTriggers";
import { useSidebar } from "./components/ui/sidebar";

export default function CreateTrigger() {
  const { isMobile } = useSidebar();
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.pathname;
  const trigger = triggers.find(
    (trigger) => trigger.trigger_hash === pathname.split("/")[1].split("-")[0]
  );

  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-row z-0 p-[30px]">
      <div className=" mt-0 lg:mt-10">
        {trigger && (
          <>
            <h1 className="text-3xl text-black text-left font-medium">
              {trigger.trigger_icon} {trigger.trigger_name}
            </h1>
            <p
              className="text-black text-left text-md m-5"
              style={{ whiteSpace: "pre-line" }}
            >
              {trigger.trigger_desc}
            </p>
            {trigger.trigger_config?.length > 0 && (
              <div className="my-10">
                <h2 className="text-xl text-black text-left font-medium my-4">
                  Configurations
                </h2>
                {trigger.trigger_config.map((config, index) => (
                  <p key={index} className="text-black text-left text-md py-1">
                    {index + 1}. {config}
                  </p>
                ))}
              </div>
            )}
            <Button
              className="flex justify-start mt-10 text-lg"
              onClick={() => navigate(`/${trigger.trigger_hash}/form`)}
            >
              Create Trigger
            </Button>
            <span className="text-xs mt-2 flex">
              You can customize the questions and frequency later.
            </span>
          </>
        )}
      </div>

      {!isMobile && (
        <div className="flex justify-center items-center w-[70%] mt-5 rounded-sm p-4 mb-2">
          <img src="/chat-preview.png" alt="Daily Standup" className="" />
        </div>
      )}
    </div>
  );
}
