import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { SidebarProvider } from "./components/ui/sidebarProvider";

createRoot(document.getElementById("root")!).render(
  // <StrictMode>
  <SidebarProvider>
    <App />
  </SidebarProvider>

  // </StrictMode>,
);
