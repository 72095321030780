import { createContext, useContext, useState, ReactNode } from "react";

// Define the context type
interface SidebarContextType {
  isCollapsed: boolean;
  toggleSidebar: () => void;
}

// Create a Context with an undefined initial value
const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

// SidebarProvider component
interface SidebarProviderProps {
  children: ReactNode;
}

export function SidebarProvider({ children }: SidebarProviderProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => setIsCollapsed((prev) => !prev);

  return (
    <SidebarContext.Provider value={{ isCollapsed, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
}

// Custom hook to use the SidebarContext
export function useSidebar(): SidebarContextType {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
}
