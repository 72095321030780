import { useState, useRef, useEffect, useMemo } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { timeZones } from "@/myTriggers";
import { TimePicker } from "../Time_picker";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const MIN_DIMENSIONS = { width: 200, height: 200 };
const MAX_DIMENSIONS = { width: 4096, height: 4096 };
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
// Form validation schema
const formSchema = z.object({
  org_name: z.string().min(1, "Organization name is required"),
  org_logo: z
    .instanceof(File, {
      message: "Please select an image file.",
    })
    .refine((file) => file.size <= MAX_FILE_SIZE, {
      message: `The image is too large. Please choose an image smaller than ${formatBytes(
        MAX_FILE_SIZE
      )}.`,
    })
    .refine((file) => ACCEPTED_IMAGE_TYPES.includes(file.type), {
      message: "Please upload a valid image file (JPEG, PNG, or WebP).",
    })
    .refine(
      (file) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              const meetsDimensions =
                img.width >= MIN_DIMENSIONS.width &&
                img.height >= MIN_DIMENSIONS.height &&
                img.width <= MAX_DIMENSIONS.width &&
                img.height <= MAX_DIMENSIONS.height;
              resolve(meetsDimensions);
            };
            img.src = e.target?.result as string;
          };
          reader.readAsDataURL(file);
        }),
      {
        message: `The image dimensions are invalid. Please upload an image between ${MIN_DIMENSIONS.width}x${MIN_DIMENSIONS.height} and ${MAX_DIMENSIONS.width}x${MAX_DIMENSIONS.height} pixels.`,
      }
    )
    .optional(),
  org_timezone: z.string().min(1, "Timezone is required"),
  org_clockin_time: z.string().min(1, "Clock-in time is required"),
  org_clockout_time: z.string().optional(),
});

interface OrganizationData {
  organisation_name: string;
  timezone: string;
  work_start: string;
}

export default function Company() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      org_name: "",
      org_timezone: "",
      org_clockin_time: "",
    },
  });

  const [organizationLogo, setOrganizationLogo] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [organizationDetails, setOrganizationDetails] =
    useState<OrganizationData | null>(null);
  const fileInputRef = useRef(null);

  // Filter time zones for search
  const filteredTimeZones: Record<string, { value: string; label: string }[]> =
    useMemo(() => {
      return Object.entries(timeZones).reduce((acc: any, [region, zones]) => {
        const filteredZones = zones.filter((zone) =>
          zone.label.toLowerCase().includes(timeZone.toLowerCase())
        );
        if (filteredZones.length > 0) acc[region] = filteredZones;
        return acc;
      }, {} as typeof timeZones);
    }, [timeZone]);

  // Fetch organization details
  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      let org_hash = "1";
      try {
        const response = await fetch(
          `http://localhost:3000/api/organization/${org_hash}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);

        const data = await response.json();
        setOrganizationDetails(data);
        console.log(data);
        form.reset(data); // Populate form with fetched data
      } catch (error) {
        console.error("Error fetching organization details:", error);
      }
    };

    fetchOrganizationDetails();
  }, [form]);

  useEffect(() => {
    if (organizationDetails) {
      form.setValue("org_name", organizationDetails.organisation_name);
      // form.setValue("org_logo", organizationDetails.logo);
      form.setValue("org_timezone", organizationDetails.timezone);
      form.setValue("org_clockin_time", organizationDetails.work_start);
    }
  }, [organizationDetails]);

  // Handle logo change
  const handleOrganizationLogoChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setOrganizationLogo(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  // Form submit handler
  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    // data.org_hash = "1";
    console.log("data", data);
    try {
      const response = await fetch("http://localhost:3000/api/organization", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) throw new Error(`Error: ${response.statusText}`);

      console.log("Response:", await response.json());
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="p-[2%] w-[70vw] h-auto flex flex-col justify-center items-center">
      <h1 className="text-lg font-bold">Company Settings</h1>
      <div className="border w-[90%] h-auto border-2 border-gray-200 rounded m-5">
        <Form {...form}>
          <form className="m-4" onSubmit={form.handleSubmit(onSubmit)}>
            {/* Organization Logo Field */}
            <FormField
              control={form.control}
              name="org_logo"
              render={({ field }) => (
                <FormItem className="w-[100%] lg:w-[70%] mb-4">
                  <FormLabel className="py-2">Organization Logo</FormLabel>
                  <div className="flex flex-col items-center">
                    <div className="relative w-24 h-24">
                      <img
                        src={
                          typeof field.value === "string"
                            ? field.value
                            : field.value && field.value instanceof File
                            ? URL.createObjectURL(field.value)
                            : organizationLogo ||
                              "https://cdn-icons-png.flaticon.com/512/5968/5968929.png"
                        }
                        alt="Organization Logo"
                        className="w-full h-full object-cover cursor-pointer"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        className="hidden"
                        ref={fileInputRef}
                        onChange={handleOrganizationLogoChange}
                      />
                    </div>
                  </div>
                </FormItem>
              )}
            />

            {/* Organization Name Field */}
            <FormField
              control={form.control}
              name="org_name"
              render={({ field }) => (
                <FormItem className="w-[100%] lg:w-[70%] mb-2">
                  <div className="flex flex-col ">
                    <FormLabel className="py-2">Organization Name</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Timezone Field */}
            <FormField
              control={form.control}
              name="org_timezone"
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <FormItem className="w-[100%] lg:w-[70%]  mb-2">
                    <div className="flex flex-col ">
                      <FormLabel className="py-2">TimeZone</FormLabel>
                      <FormControl>
                        <Select
                          value={value}
                          onValueChange={(timezone) => onChange(timezone)}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select a timezone" />
                          </SelectTrigger>
                          <SelectContent className="w-[100%] h-[300px] overflow-y-scroll">
                            <div className="p-2">
                              <Input
                                placeholder="Search time zones..."
                                value={timeZone}
                                onChange={(e) => setTimeZone(e.target.value)}
                                className="w-full mb-2"
                              />
                            </div>
                            {Object.entries(filteredTimeZones).map(
                              ([region, zones]) => (
                                <SelectGroup key={region}>
                                  <SelectLabel>{region}</SelectLabel>
                                  {zones.map((zone) => (
                                    <SelectItem
                                      key={zone.value}
                                      value={zone.value}
                                    >
                                      {zone.label}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              )
                            )}
                          </SelectContent>
                        </Select>
                      </FormControl>
                    </div>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            {/* Work Start and End Time Fields */}
            <FormField
              control={form.control}
              name="org_clockin_time"
              render={({ field }) => (
                <TimePicker
                  label="Work Start Time"
                  value={field.value}
                  onChange={field.onChange}
                  disabled={false}
                />
              )}
            />
            <FormField
              control={form.control}
              name="org_clockout_time"
              render={({ field }) => (
                <TimePicker
                  label="Out of Office Time"
                  value={field.value || "00:00"}
                  onChange={field.onChange}
                  disabled={true}
                />
              )}
            />
            <Button type="submit" className="mt-5">
              Save Changes
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
