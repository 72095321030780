import { useState, useCallback } from "react";
import { Button } from "./components/ui/button";
import { useLocation } from "react-router-dom";
import { PersonIcon } from "@radix-ui/react-icons";
import TriggerModal from "./TriggerModal";
import { triggers } from "./myTriggers";
import { LogOut, CirclePlus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useSidebar } from "./components/ui/sidebar";

interface HeaderProps {
  setIsModalOpen: (open: boolean) => void;
  isModalOpen: boolean;
}

export function Header({ isModalOpen, setIsModalOpen }: HeaderProps) {
  const location = useLocation();
  const [showLogout, setShowLogout] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const navigate = useNavigate();
  const { isMobile } = useSidebar();

  const handleLogout = () => {
    setShowLogout(false);
    navigate("/login");
  };

  const handleOpenChange = (open: boolean) => {
    setIsModalOpen(open);
  };

  // Using useCallback to prevent unnecessary re-renders
  const toggleLogout = useCallback((show: boolean) => {
    setShowLogout(show);
  }, []);

  return (
    <div>
      <header className="flex justify-end items-center h-[70px] py-4 md:px-4 md:py-4 md:justify-start fixed inset-x-0 top-0 border-b-[1px] border-[#C4C4C4] bg-white z-5">
        <div className="flex justify-end gap-10 absolute right-1  text-xl py-5">
          {/* Only display on screens wider than 768px */}
          {!isMobile && <p>Release Notes</p>}

          <Button
            className="text-lg"
            onClick={() => setIsModalOpen(true)} // Opens the modal
          >
            <CirclePlus /> Add Trigger
          </Button>

          {/* User icon with logout option */}
          <div
            className="relative"
            onMouseEnter={() => toggleLogout(true)}
            onMouseLeave={() => toggleLogout(false)}
            onClick={() => toggleLogout(!showLogout)}
          >
            <PersonIcon
              className="w-8 h-8 p-[2px] mr-4 border-2 rounded-full border-[black] cursor-pointer"
              aria-hidden="true"
            />
            {showLogout && (
              <div
                className="absolute right-0 mt-1 p-2 bg-[#fafafa] border rounded z-50"
                onClick={handleLogout}
              >
                <span
                  className="cursor-pointer flex justify-center items-center"
                >
                  <LogOut className="w-5 h-5 mx-1" />
                  Logout
                </span>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Trigger Modal */}
      <TriggerModal
        isOpen={isModalOpen}
        onOpenChange={handleOpenChange}
        triggers={triggers}
      />
    </div>
  );
}
