import { cn } from "@/lib/utils";
import TriggerModal from "./TriggerModal";
import { useEffect, useState } from "react";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetFooter,
  SheetClose,
} from "@/components/ui/sheet";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import AvailableTriggers from "./AvailableTriggers";
import { Trigger } from "./types";
import { CirclePlus } from "lucide-react";
import { triggers, timeZones, OPTIONS } from "./myTriggers";
import { Button } from "./components/ui/button";
import { Label } from "./components/ui/label";
import { Input } from "./components/ui/input";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { serverUrl } from "./types";

interface CardProps {
  className?: string;
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
}
export function Triggers({
  className,
  isModalOpen,
  setIsModalOpen,
  ...props
}: CardProps) {
  const navigate = useNavigate();
  const [yourTriggers, setYourTriggers] = useState<Trigger[]>([]);

  const handleOpenChange = (open: boolean) => setIsModalOpen(open);
  const [selectedTrigger, setSelectedTrigger] = useState<Trigger | null>(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [triggerQuestions, setTriggerQuestions] = useState([]);

  const openSheet = (trigger: Trigger) => {
    setSelectedTrigger(trigger);
    setIsSheetOpen(true);
  };

  const openEditForm = (trigger: Trigger | null) => {
    navigate(`/${trigger?.trigger_id}`);
  };
  const openDeleteForm = async (trigger: Trigger | null) => {
    try {
      const response = await fetch(
        `http://localhost:3000/api/triggers/${trigger?.trigger_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", response);
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      console.log("Trigger deleted successfully");
      setIsSheetOpen(false);
      getYourTriggers();
    } catch (error: any) {
      console.error("Error in openDeleteForm:", error.message);
      alert("Failed to delete trigger. Please try again later.");
    }
  };

  const regions: (keyof typeof timeZones)[] = Object.keys(
    timeZones
  ) as (keyof typeof timeZones)[];

  const getTimezoneLabel = (timezoneValue: string): string => {
    for (const region of regions) {
      const found = timeZones[region]?.find((tz) => tz.value === timezoneValue);
      if (found) {
        return found.label;
      }
    }
    return "No timezone specified";
  };

  async function getYourTriggers() {
    try {
      const response = await fetch(`http://localhost:3000/api/triggers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      // console.log("Triggers fetched:", await response.text());
      const data = await response.json();
      console.log("Triggers fetched:", data);
      setYourTriggers(data);

      // if (data.length > 0) {
      //   setSelectedTrigger(data[0]);
      //   console.log("Selected Trigger:", data[0]);
      // } else {
      //   console.warn("No triggers found.");
      //   setSelectedTrigger(null);
      // }
    } catch (error: any) {
      console.error("Error in getYourTriggers:", error.message);
      alert("Failed to fetch triggers. Please try again later.");
    }
  }

  async function getTriggerQuestions() {
    if (!selectedTrigger) {
      return <p> Loading...</p>;
    }

    try {
      console.log(
        "Fetching questions for Trigger ID:",
        selectedTrigger.trigger_id
      );
      const response = await fetch(
        `http://localhost:3000/api/triggers/${selectedTrigger.trigger_id}/questions`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      console.log("Questions fetched:", data);
      setTriggerQuestions(data.map((q: any) => q.question_text));
    } catch (error: any) {
      console.error("Failed to fetch trigger questions:", error.message);
    }
  }

  useEffect(() => {
    getYourTriggers();
    getTriggerQuestions();
  }, []);
  useEffect(() => {
    getTriggerQuestions();
  }, [selectedTrigger]);

  return (
    <div className=" p-[30px]">
      <div className="border-[1px] border-[#C4C4C4] rounded-sm p-4 pb-1 mb-4 bg-white">
        <h1 className="text-xl font-medium text-left text-slate-900 flex items-center">
          <span className="text-[#266DFB] font-bold mr-2 text-2xl">💡</span>
          Your Triggers {`(${yourTriggers.length})`}
        </h1>
        <div
          className={`${
            yourTriggers.length === 0
              ? "flex flex-col justify-center items-center py-2"
              : "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2 my-4 "
          }`}
        >
          <Card
            className={cn(
              "cursor-pointer  flex flex-row justify-center max-w-[400px] w-full h-auto hover:shadow-lg overflow-hidden transition-all duration-100 trigger-card ",
              className
            )}
            {...props}
            onClick={() => setIsModalOpen(true)}
            aria-label="Add Trigger"
          >
            <CardHeader>
              <CardTitle className="flex justify-center items-center my-auto text-black text-2xl">
                <CirclePlus className="me-4 size-7 trigger-icon" /> Add Trigger
              </CardTitle>
            </CardHeader>
          </Card>
          {yourTriggers.length !== 0 ? (
            yourTriggers.map((trigger, index) => (
              <Card
                key={index}
                className={cn(
                  "cursor-pointer  flex flex-row justify-center w-full h-auto hover:shadow-lg overflow-hidden transition-all duration-100 trigger-card",
                  className
                )}
                {...props}
                onClick={() => openSheet(trigger)}
              >
                <div className="flex items-center justify-center text-[#e2bb50] text-[30px] lg:text-4xl md:text-[30px] sm:text-[30px] ms-3 trigger-icon">
                  {trigger.trigger_icon}
                </div>
                <CardHeader className="relative">
                  {/* Wrap Title and Channel Name in a Flex Container */}
                  <div className="flex justify-between items-center">
                    <CardTitle className="flex flex-start text-left">
                      {trigger.trigger_name}
                    </CardTitle>
                    {trigger?.trigger_recipients &&
                      trigger.trigger_recipients.length > 0 && (
                        <span className="text-sm text-muted-foreground bg-[#f0f0f0] rounded px-1 w-fit">
                          {`#${
                            trigger.trigger_recipients.length > 2
                              ? `${trigger.trigger_recipients.length} recipients`
                              : trigger.trigger_recipients.join(", ")
                          }`}
                        </span>
                      )}
                  </div>

                  <CardDescription className="flex flex-start text-left">
                    {`${
                      trigger.trigger_type === "start_from_scratch"
                        ? trigger.trigger_name
                        : trigger?.trigger_type
                    } will be triggered at ${trigger.trigger_time} on ${
                      trigger?.trigger_days && trigger.trigger_days.length > 0
                        ? trigger.trigger_days.length > 2
                          ? `${trigger.trigger_days.length} days`
                          : trigger.trigger_days.join(", ")
                        : "No specific days"
                    } in a week to ${
                      trigger?.trigger_participants &&
                      trigger.trigger_participants.length > 0
                        ? trigger.trigger_participants.length > 2
                          ? `${trigger.trigger_participants.length} participants`
                          : trigger.trigger_participants.join(", ")
                        : "No participants"
                    }`}
                  </CardDescription>
                </CardHeader>
              </Card>
            ))
          ) : (
            <div className="flex flex-col justify-center items-center py-8">
              <p>
                {`No triggers created yet. Click `}
                <strong>{`'Add Trigger'`}</strong>
                {` to set up triggers for your workflow.`}
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="border-[1px] border-[#C4C4C4] rounded-sm p-4 mb-2 bg-white">
        <AvailableTriggers
          className={className}
          onClose={() => setIsModalOpen(false)}
          setIsModalOpen={setIsModalOpen}
          // props={props}
          // triggers={triggers}
          // onCreateTrigger={() => {}}
        />
      </div>

      <TriggerModal
        isOpen={isModalOpen}
        onOpenChange={handleOpenChange}
        className={className}
        props={props}
        triggers={triggers}
      />
      <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle className="flex justify-center">
              {selectedTrigger?.trigger_name}
            </SheetTitle>
            {/* <SheetDescription>{selectedTrigger?.trigger_desc}</SheetDescription> */}
          </SheetHeader>
          <div className="grid gap-4 py-4">
            <table className="table-fixed w-[100%] ">
              <thead>
                <tr>
                  <th className="w-[40%]"></th>
                  <th className="w-[60%]"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2 border-b text-left w-[50%] ">
                    <strong>Frequency</strong>
                  </td>
                  <td className="p-2 border-b text-left">
                    {selectedTrigger?.trigger_frequency === "0"
                      ? "Daily"
                      : selectedTrigger?.trigger_frequency === "1"
                      ? "Weekly"
                      : selectedTrigger?.trigger_frequency === "2"
                      ? "Bi-Weekly"
                      : selectedTrigger?.trigger_frequency === "3"
                      ? "Monthly"
                      : selectedTrigger?.trigger_frequency === "4"
                      ? "Only once"
                      : "Custom"}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border-b text-left">
                    <strong>Days</strong>
                  </td>
                  <td className="p-2 border-b text-left">
                    {selectedTrigger?.trigger_days?.join(", ") ||
                      "No days specified"}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border-b text-left">
                    <strong>Time</strong>
                  </td>
                  <td className="p-2 border-b text-left">
                    {selectedTrigger?.trigger_time || "No time specified"}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border-b text-left">
                    <strong>Timezone</strong>
                  </td>
                  <td className="p-2 border-b text-left">
                    {getTimezoneLabel(selectedTrigger?.trigger_timezone || "")}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border-b text-left">
                    <strong>Participants</strong>
                  </td>
                  <td className="p-2 border-b text-left">
                    {selectedTrigger?.trigger_participants?.join(", ") ||
                      "No participants"}
                  </td>
                </tr>
                {selectedTrigger?.trigger_type !== "reminder" ? (
                  <>
                    <tr>
                      <td className="p-2 border-b text-left">
                        <strong>Recipients</strong>
                      </td>
                      <td className="p-2 border-b text-left">
                        {selectedTrigger?.trigger_recipients?.join(", ") ||
                          "No recipients"}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 border-b text-left ">
                        <strong>Questions</strong>
                      </td>
                      <td className="p-2 border-b text-left">
                        {triggerQuestions.map((question, index) => (
                          <p key={index}>
                            {index + 1}. {question}
                          </p>
                        ))}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td className="p-2 border-b text-left">
                      <strong>Message</strong>
                    </td>
                    <td className="p-2 border-b text-left">
                      {selectedTrigger?.content || "No message provided"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex gap-2 justify-end">
            <Button type="submit" onClick={() => openEditForm(selectedTrigger)}>
              Edit trigger
            </Button>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button className="bg-red-600 hover:bg-red-500 text-white">
                  Delete trigger
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This action cannot be undone. This will permanently delete
                    your trigger and remove your data from our servers.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="m-2">
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => openDeleteForm(selectedTrigger)}
                  >
                    Continue
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
}
