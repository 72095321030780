import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Button } from "../components/ui/button";
import { ChevronsUpDown } from "lucide-react";
import { timeZones, items, OPTIONS, USERS, CHANNELS } from "../myTriggers";
import { TimePicker } from "../Time_picker";
import { Input } from "../components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useState, useRef, useEffect, useMemo } from "react";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const MIN_DIMENSIONS = { width: 200, height: 200 };
const MAX_DIMENSIONS = { width: 4096, height: 4096 };
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const formSchema = z.object({
  //image
  profile_pic: z
    .instanceof(File, {
      message: "Please select an image file.",
    })
    .refine((file) => file.size <= MAX_FILE_SIZE, {
      message: `The image is too large. Please choose an image smaller than ${formatBytes(
        MAX_FILE_SIZE
      )}.`,
    })
    .refine((file) => ACCEPTED_IMAGE_TYPES.includes(file.type), {
      message: "Please upload a valid image file (JPEG, PNG, or WebP).",
    })
    .refine(
      (file) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              const meetsDimensions =
                img.width >= MIN_DIMENSIONS.width &&
                img.height >= MIN_DIMENSIONS.height &&
                img.width <= MAX_DIMENSIONS.width &&
                img.height <= MAX_DIMENSIONS.height;
              resolve(meetsDimensions);
            };
            img.src = e.target?.result as string;
          };
          reader.readAsDataURL(file);
        }),
      {
        message: `The image dimensions are invalid. Please upload an image between ${MIN_DIMENSIONS.width}x${MIN_DIMENSIONS.height} and ${MAX_DIMENSIONS.width}x${MAX_DIMENSIONS.height} pixels.`,
      }
    )
    .optional(),
  name: z.string().min(1, { message: "Trigger name is required" }),
  email: z.string().email({ message: "Invalid email address" }),
  timezone: z.string().min(1, { message: "Timezone is required" }),
  clockin_time: z.string().min(1, { message: "Time is required" }),
  clockout_time: z.string().optional(),
});

const passwordFormSchema = z
  .object({
    password: z
      .string()
      .min(8, { message: "At least 8 characters required" })
      .refine((val) => /[A-Z]/.test(val), {
        message: "Must include at least one uppercase letter",
      })
      .refine((val) => /[a-z]/.test(val), {
        message: "Must include at least one lowercase letter",
      })
      .refine((val) => /[0-9]/.test(val), {
        message: "Must include at least one number",
      })
      .refine((val) => /[!@#$%^&*(),.?":{}|<>]/.test(val), {
        message: "Must include at least one special character",
      }),
    confirm_password: z
      .string()
      .min(8, { message: "At least 8 characters required" }),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: "Passwords do not match",
    path: ["confirm_password"],
  });

interface ProfileData {
  name: string;
  email: string;
  timezone: string;
  work_start_time: string;
  work_end_time: string;
}
export default function Accounts() {
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [loading, setLoading] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Fetch profile data from the server
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch(
          "http://localhost:3000/api/auth/profileData/1",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
        setProfileData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchProfileData();
  }, []);
  // Set form values from profile data
  useEffect(() => {
    if (profileData) {
      form.setValue("name", profileData.name);
      form.setValue("email", profileData.email);
      form.setValue("timezone", profileData.timezone);
      form.setValue("clockin_time", profileData.work_start_time);
    }
  }, [profileData]);

  // Set default values for form
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: profileData?.name || "",
      email: profileData?.email || "",
      timezone: profileData?.timezone || "",
      clockin_time: profileData?.work_start_time || "",
      // clockout_time: "",
    },
  });

  const passwordForm = useForm<z.infer<typeof passwordFormSchema>>({
    resolver: zodResolver(passwordFormSchema),
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });

  const getPasswordStrength = (password: any) => {
    const criteria = [
      /[A-Z]/.test(password), // Uppercase
      /[a-z]/.test(password), // Lowercase
      /[0-9]/.test(password), // Number
      /[!@#$%^&*(),.?":{}|<>]/.test(password), // Special character
    ];

    const score = criteria.filter(Boolean).length;

    if (score === 4) return "Strong";
    if (score >= 2) return "Moderate";
    return "Weak";
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [timeZone, setTimeZone] = useState("");
  const [isProfile, setIsProfile] = useState(true);
  const [profilePicture, setProfilePicture] = useState("");

  //filtered timezones
  const filteredTimeZones: Record<string, { value: string; label: string }[]> =
    useMemo(() => {
      return Object.entries(timeZones).reduce((acc: any, [region, zones]) => {
        const filteredZones = zones.filter((zone) =>
          zone.label.toLowerCase().includes(timeZone.toLowerCase())
        );
        if (filteredZones.length > 0) acc[region] = filteredZones;
        return acc;
      }, {} as typeof timeZones);
    }, [timeZone]);

  const handleProfilePictureChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Check size and type
    if (file.size > MAX_FILE_SIZE) {
      alert(`File is too large. Max size is ${formatBytes(MAX_FILE_SIZE)}.`);
      return;
    }
    if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
      alert(
        "Invalid file type. Please upload a valid image file (JPEG, PNG, or WebP)."
      );
      return;
    }

    // Check dimensions
    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = new Image();
      img.onload = () => {
        if (
          img.width < MIN_DIMENSIONS.width ||
          img.height < MIN_DIMENSIONS.height ||
          img.width > MAX_DIMENSIONS.width ||
          img.height > MAX_DIMENSIONS.height
        ) {
          alert(
            `Invalid image dimensions. Please upload an image between ${MIN_DIMENSIONS.width}x${MIN_DIMENSIONS.height} and ${MAX_DIMENSIONS.width}x${MAX_DIMENSIONS.height}.`
          );
          return;
        }
        // Update form with the selected image
        const imageSrc = file;
        form.setValue("profile_pic", imageSrc);
      };
      img.src = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    console.log("data", data);
    // data.user_hash = "1";

    try {
      const response = await fetch(
        "http://localhost:3000/api/auth/profileData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Data received:", responseData);
      return responseData;
    } catch (error) {
      console.log("Error in ProfileData:", error);
      throw error;
    }
  };

  const onSubmitPassword = async (data: z.infer<typeof passwordFormSchema>) => {
    console.log("Submitted Data:", data);
    const newData = {
      user_hash: "1",
      password: data.password,
    };

    try {
      const response = await fetch("http://localhost:3000/api/auth/password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Data received:", data);
      passwordForm.reset();
      return data;
    } catch (error) {
      console.log("error in profiledata", error);
      throw error;
    }
  };
  if (loading) {
    return <div>Loading...</div>;
  }
  // if (!profileData) {
  //   return <div>No data available</div>;
  // }

  return (
    <div className="p-[2%] w-[70vw] h-auto flex flex-col justify-center items-center">
      <h1 className="text-lg font-bold">My Account</h1>
      <div className="border w-[90%] h-auto border-2 border-gray-200 rounded m-5">
        <div className="grid grid-cols-2 items-center border-b-2 border-gray-200 ">
          <h1
            className={`text-md border-r-2 border-gray-200 text-center py-3 cursor-pointer ${
              isProfile ? "font-bold bg-[#f4f4f4]" : ""
            }`}
            onClick={() => setIsProfile(true)}
          >
            Profile
          </h1>
          <h1
            className={`text-md text-center py-3 cursor-pointer ${
              isProfile ? "" : "font-bold bg-[#f4f4f4]"
            }`}
            onClick={() => setIsProfile(false)}
          >
            Password
          </h1>
        </div>
        {isProfile && (
          <Form {...form}>
            <form className="m-4" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="profile_pic"
                render={({ field }) => (
                  <FormItem className="w-[100%] lg:w-[70%]  mb-2">
                    <FormLabel className="py-2">Profile Picture</FormLabel>
                    <div className="flex flex-col items-center">
                      <div className="relative w-24 h-24">
                        <img
                          src={
                            typeof field.value === "string"
                              ? field.value
                              : field.value && field.value instanceof File
                              ? URL.createObjectURL(field.value)
                              : profilePicture || "/logo-v2.png"
                          }
                          alt="Profile"
                          className="w-full h-full object-cover cursor-pointer"
                        />
                        <input
                          type="file"
                          accept="image/*"
                          className="hidden"
                          ref={fileInputRef}
                          onChange={handleProfilePictureChange}
                        />
                      </div>
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="w-[100%] lg:w-[70%]  mb-2">
                    <div className="flex flex-col ">
                      <FormLabel className="py-2">Your Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder=""
                          {...field}
                          defaultValue={profileData?.name}
                        />
                      </FormControl>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="w-[100%] lg:w-[70%]  mb-2">
                    <div className="flex flex-col ">
                      <FormLabel className="py-2">Email</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="timezone"
                render={({ field }) => {
                  const { onChange, value } = field;
                  return (
                    <FormItem className="w-[100%] lg:w-[70%]  mb-2">
                      <div className="flex flex-col ">
                        <FormLabel className="py-2">TimeZone</FormLabel>
                        <FormControl>
                          <Select
                            value={value || ""}
                            onValueChange={(timezone) => {
                              onChange(timezone);
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select a timezone" />
                            </SelectTrigger>
                            <SelectContent className="w-[100%] h-[300px] overflow-y-scroll">
                              <div className="p-2">
                                <Input
                                  placeholder="Search time zones..."
                                  value={timeZone}
                                  onChange={(e) => setTimeZone(e.target.value)}
                                  className="w-full mb-2"
                                />
                              </div>
                              {Object.entries(filteredTimeZones).map(
                                ([region, zones]) => (
                                  <SelectGroup key={region}>
                                    <SelectLabel>{region}</SelectLabel>
                                    {zones.map((zone) => (
                                      <SelectItem
                                        key={zone.value}
                                        value={zone.value}
                                      >
                                        {zone.label}
                                      </SelectItem>
                                    ))}
                                  </SelectGroup>
                                )
                              )}
                            </SelectContent>
                          </Select>
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="clockin_time"
                render={({ field }) => (
                  <TimePicker
                    label="Work Start Time"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={false}
                  />
                )}
              />
              <FormField
                control={form.control}
                name="clockout_time"
                render={({ field }) => (
                  <TimePicker
                    label="Out of Office Time"
                    value={field.value || "00:00"}
                    onChange={field.onChange}
                    disabled={true}
                  />
                )}
              />
              <Button type="submit" className="mt-5">
                Save Changes
              </Button>
            </form>
          </Form>
        )}

        {!isProfile && (
          <Form {...passwordForm}>
            <form
              className="m-4"
              onSubmit={passwordForm.handleSubmit(onSubmitPassword)}
            >
              <div className="flex flex-col justify-center items-center">
                <h1 className="text-xl font-bold py-5">Change Password</h1>
                <FormField
                  control={passwordForm.control}
                  name="password"
                  render={({ field }) => {
                    return (
                      <FormItem className="w-[100%] lg:w-[50%] mb-2">
                        <div className="flex flex-col">
                          <FormLabel className="py-2">New Password</FormLabel>
                          <FormControl>
                            <div className="relative">
                              <Input
                                placeholder=""
                                {...field}
                                id="new"
                                type={showPassword ? "text" : "password"}
                                className="pr-16"
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                              />
                              {/* Password Strength */}
                              {passwordForm.getValues("password") && (
                                <span
                                  className={`absolute inset-y-0 right-3 flex items-center text-xs ${
                                    getPasswordStrength(
                                      passwordForm.getValues("password")
                                    ) === "Strong"
                                      ? "text-green-500"
                                      : getPasswordStrength(
                                          passwordForm.getValues("password")
                                        ) === "Moderate"
                                      ? "text-yellow-500"
                                      : "text-red-500"
                                  }`}
                                >
                                  {getPasswordStrength(
                                    passwordForm.getValues("password")
                                  )}
                                </span>
                              )}
                              {/* Toggle Button
                              <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute inset-y-0 right-3 flex items-center text-sm text-gray-500 focus:outline-none"
                              >
                                {showPassword ? "Hide" : "Show"}
                              </button> */}
                            </div>
                          </FormControl>
                        </div>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={passwordForm.control}
                  name="confirm_password"
                  render={({ field }) => {
                    return (
                      <FormItem className="w-[100%] lg:w-[50%] mb-2">
                        <div className="flex flex-col">
                          <FormLabel className="py-2">
                            Confirm Password
                          </FormLabel>
                          <FormControl>
                            <div className="relative">
                              <Input
                                placeholder=""
                                {...field}
                                id="confirm"
                                type={showConfirmPassword ? "text" : "password"}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                              />
                              {/* Toggle Button */}
                              <button
                                type="button"
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                className="absolute inset-y-0 right-3 flex items-center text-sm text-gray-500 focus:outline-none"
                              >
                                {showConfirmPassword ? "Hide" : "Show"}
                              </button>
                            </div>
                          </FormControl>
                        </div>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>

              <Button type="submit" className="mt-5">
                Save Changes
              </Button>
            </form>
          </Form>
        )}
      </div>
    </div>
  );
}
