import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Trigger, Trigger_question } from "./types";
import { ReminderForm } from "./forms/reminder-form";
import { StandupForm } from "./forms/standup-form";
import { RetrospectiveForm } from "./forms/retrospective-form";
import { SprintHealthCheckForm } from "./forms/sprint-health-check-form";
import { IcebreakerForm } from "./forms/icebreaker-form";
import { OneOneMeetingForm } from "./forms/1-1-meeting-form";
import { WellBeingCheckInForm } from "./forms/wellBeing-form";
import { NewForm } from "./forms/new-form";
import { Monthly1_1Form } from "./forms/monthly1-1-form";

export default function RouteForm() {
  const { trigger_id } = useParams<{ trigger_id: string }>();
  const [trigger, setTrigger] = useState<Trigger | null>(null);
  const [trigger_questions, setTriggerQuestions] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchTrigger = async () => {
    try {
      const response = await fetch(
        `http://localhost:3000/api/triggers/${trigger_id}`
      );
      const data = await response.json();
      setTrigger(data);
    } catch (error: any) {
      console.error("Failed to fetch trigger:", error.message);
    }
  };

  const fetchTriggerQuestions = async () => {
    try {
      const response = await fetch(
        `http://localhost:3000/api/triggers/${trigger_id}/questions`
      );
      const data = await response.json();
      const ques = data.map((q: any) => q.question_text);
      setTriggerQuestions(ques);
    } catch (error: any) {
      console.error("Failed to fetch trigger questions:", error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchTrigger(), fetchTriggerQuestions()]);
      setLoading(false);
    };

    fetchData();
  }, [trigger_id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  switch (trigger?.trigger_type) {
    case "reminder":
      return <ReminderForm trigger={trigger} />;
    case "standup":
      return (
        <StandupForm trigger={trigger} trigger_questions={trigger_questions} />
      );
    case "retrospective":
      return (
        <RetrospectiveForm
          trigger={trigger}
          trigger_questions={trigger_questions}
        />
      );
    case "sprint_health_check":
      return (
        <SprintHealthCheckForm
          trigger={trigger}
          trigger_questions={trigger_questions}
        />
      );
    case "icebreaker":
      return (
        <IcebreakerForm
          trigger={trigger}
          trigger_questions={trigger_questions}
        />
      );
    case "1_1_meeting":
      return (
        <OneOneMeetingForm
          trigger={trigger}
          trigger_questions={trigger_questions}
        />
      );
    case "well_being_check_in":
      return (
        <WellBeingCheckInForm
          trigger={trigger}
          trigger_questions={trigger_questions}
        />
      );
    case "start_from_scratch":
      return (
        <NewForm trigger={trigger} trigger_questions={trigger_questions} />
      );
    case "monthly_1_1":
      return (
        <Monthly1_1Form
          trigger={trigger}
          trigger_questions={trigger_questions}
        />
      );
    default:
      return <div>No form found for this trigger type.</div>;
  }
}
