import React, { useState } from "react";
import { useTrigger } from "./triggerContext";
import { AppSidebar } from "@/components/ui/app-sidebar";
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from "@/components/ui/sidebar";
import { Triggers } from "./Triggers";
import Settings from "./settings/settings";
import ComingSoon from "./comingSoon";
import CreateTrigger from "./CreateTriggerPreview";
import { ReminderForm } from "./forms/reminder-form";
import { StandupForm } from "./forms/standup-form";
import { RetrospectiveForm } from "./forms/retrospective-form";
import { SprintHealthCheckForm } from "./forms/sprint-health-check-form";
import { IcebreakerForm } from "./forms/icebreaker-form";
import { OneOneMeetingForm } from "./forms/1-1-meeting-form";
import { WellBeingCheckInForm } from "./forms/wellBeing-form";
import { NewForm } from "./forms/new-form";
import { Monthly1_1Form } from "./forms/monthly1-1-form";
import { Header } from "./Header";
import { PreviewPage } from "./PreviewPage";
import { Route, Routes } from "react-router-dom";
import RouteForm from "./routeForm";
import { Edit } from "lucide-react";

interface LayoutProps {
  currentPath: string; // Accept current path as prop
}

export default function Layout() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false); // State for sidebar collapse
  const { trigger } = useTrigger();

  const handleOpenChange = (open: boolean) => {
    setIsModalOpen(open);
  };

  // Toggle sidebar collapse
  const toggleSidebar = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset>
        <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger
              onClick={toggleSidebar}
              className={`z-30 fixed top-0 transition-all duration-300 ${
                isCollapsed ? "md:left-[3rem] " : "md:left-[13rem] "
              } left-0`}
            />
          </div>
        </header>
        <div className=" mx-0 my-0  h-full">
          <Routes>
            <Route
              path="/home"
              element={
                <Triggers
                  isModalOpen={isModalOpen}
                  setIsModalOpen={handleOpenChange}
                />
              }
            />
            <Route path="/:trigger_id" element={<RouteForm />} />

            <Route path="/settings/account" element={<Settings />} />
            <Route path="/settings/company" element={<Settings />} />
            <Route path="/settings/members" element={<Settings />} />
            <Route path="/billing" element={<ComingSoon />} />
            <Route path="/profile" element={<ComingSoon />} />
            <Route path="/reminder-preview" element={<CreateTrigger />} />
            <Route path="/standup-preview" element={<CreateTrigger />} />
            <Route path="/retrospective-preview" element={<CreateTrigger />} />
            <Route
              path="/sprint_health_check-preview"
              element={<CreateTrigger />}
            />
            <Route path="/icebreaker-preview" element={<CreateTrigger />} />
            <Route path="/1_1_meeting-preview" element={<CreateTrigger />} />
            <Route
              path="/well_being_check_in-preview"
              element={<CreateTrigger />}
            />
            <Route
              path="/start_from_scratch-preview"
              element={<CreateTrigger />}
            />
            <Route path="/monthly_1_1-preview" element={<CreateTrigger />} />
            <Route
              path="/reminder/form"
              element={<ReminderForm trigger={null} />}
            />
            <Route
              path="/standup/form"
              element={<StandupForm trigger={null} trigger_questions={[]} />}
            />
            <Route
              path="/retrospective/form"
              element={
                <RetrospectiveForm trigger={null} trigger_questions={[]} />
              }
            />
            <Route
              path="/sprint_health_check/form"
              element={
                <SprintHealthCheckForm trigger={null} trigger_questions={[]} />
              }
            />
            <Route
              path="/icebreaker/form"
              element={<IcebreakerForm trigger={null} trigger_questions={[]} />}
            />
            <Route
              path="/1_1_meeting/form"
              element={
                <OneOneMeetingForm trigger={null} trigger_questions={[]} />
              }
            />
            <Route
              path="/well_being_check_in/form"
              element={
                <WellBeingCheckInForm trigger={null} trigger_questions={[]} />
              }
            />
            <Route
              path="/start_from_scratch/form"
              element={<NewForm trigger={null} trigger_questions={[]} />}
            />
            <Route
              path="/monthly_1_1/form"
              element={<Monthly1_1Form trigger={null} trigger_questions={[]} />}
            />
          </Routes>
          <Header setIsModalOpen={handleOpenChange} isModalOpen={isModalOpen} />
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
}
