
export const triggers = [
    {
      triggerModel_id: 1,
      trigger_hash: "reminder",
      trigger_name: "Send Reminders",
      trigger_desc: "Send personalized reminders to your team members effortlessly.",
      trigger_icon: "✍️",
      trigger_config: ["Type a Reminder", "Select a Date and Time", "Select a broadcasting channel or person"]
    },
    {
      triggerModel_id: 2,
      trigger_hash: "standup",
      trigger_name: "Daily Stand-ups",
      trigger_desc:
        "Track daily updates and blockers effortlessly, keeping team aligned.",
      trigger_icon: "☀️",
      trigger_config: ["How are you feeling?", "What did you complete in your previous workday?", "What you are going to do today?", "Do you have any blockers?"]
    },
    {
      triggerModel_id: 3,
      trigger_hash: "monthly_1_1",
      trigger_name: "Monthly 1-on-1",
      trigger_desc: "Gather confidential feedback in a private, secure report",
      trigger_icon: "🤝",
      trigger_config: ["What was your highlight of your month?", "Was anything a particular struggle?","Any blockers I can help remove?","How satisfied do you feel about the work you've completed this week?","Do you feel that WFH affects the qualtiy of your work?", "Do you feel satisfied with your work-life balance?" ]
    },
    {
      triggerModel_id: 4,
      trigger_hash: "retrospective",
      trigger_name: "Retrospective",
      trigger_desc:
        "Check whether the Sprint is going well or deliveries are under risk.",
      trigger_icon: "🤔",
      trigger_config: ["What went well?", "What did you learn?", "What can be improved?(i.e it went wrong, it wasn't delivered or didn't go as expected)"]
    },
    {
      triggerModel_id: 5,
      trigger_hash: "sprint_health_check",
      trigger_name: "Sprint Health Check",
      trigger_desc:
        "Keep your whole team in the loop with daily updates on progress.",
      trigger_icon: "🧑‍💻",
      trigger_config: ["How confident are you that you will complete the task for this sprint?","How confident are you that the rest of the team will complete the task?", "Do you have any recommendations for managing better the Sprint and priorities?" ]
  
    },
    {
      triggerModel_id: 6,
      trigger_hash: "icebreaker",
      trigger_name: "Icebreaker to start off the week",
      trigger_desc:
        "A simple icebreaker question to kick off the week.",
      trigger_icon: "🤗",
      trigger_config: ["How was your weekend? Is there anything fun you'd like to share? Jokes are accepted.", ]
  
    },
    {
      triggerModel_id: 7,
      trigger_hash:"1_1_meeting",
      trigger_name: "1-1 meeting",
      trigger_desc:
        "Essential questions for periodic 1:1 check-ins to drive improvement.",
      trigger_icon: "🤝",
      trigger_config: ["How are you feeling, out 5?","What could improve this score, if possible?", "What's going great for you right now?"]
    },
    {
      triggerModel_id: 8,
      trigger_hash: "well_being_check_in",
      trigger_name: "Well-being Check-in",
      trigger_desc:
        "Check in on your teammates and make sure everyone's doing OK.",
      trigger_icon: "🫰",
      trigger_config: ["Thinking about this past week: how productive do you feel you were?","Do you feel you've had more wins or blocks this past week?","How stressful did you find work in general this week?", "How satisfied you feel about the work you've completed this week?", "Do you feel that WFH affects the qualtiy of your work?", "Do you feel satisfied with your work-life balance?" ]
    },
    {
      triggerModel_id: 9,
      trigger_hash: "start_from_scratch",
      trigger_name: "Start from Scratch",
      trigger_desc:
        "Create your own questions for a custom check-in with your team.",
      trigger_icon: "⚡",
      trigger_config: ["How are you feeling, out 5","What could improve this score, if possible?", "What's going great for you right now?"]
    }, //changeeee!!!
  ];

  export const timeZones = {
    "North America": [
      { value: "est", label: "Eastern Standard Time (EST)" },
      { value: "cst", label: "Central Standard Time (CST)" },
      { value: "mst", label: "Mountain Standard Time (MST)" },
      { value: "pst", label: "Pacific Standard Time (PST)" },
      { value: "akst", label: "Alaska Standard Time (AKST)" },
      { value: "hst", label: "Hawaii Standard Time (HST)" },
    ],
    "Europe & Africa": [
      { value: "gmt", label: "Greenwich Mean Time (GMT)" },
      { value: "cet", label: "Central European Time (CET)" },
      { value: "eet", label: "Eastern European Time (EET)" },
      { value: "west", label: "Western European Summer Time (WEST)" },
      { value: "cat", label: "Central Africa Time (CAT)" },
      { value: "eat", label: "East Africa Time (EAT)" },
    ],
    Asia: [
      { value: "msk", label: "Moscow Time (MSK)" },
      { value: "ist", label: "India Standard Time (IST)" },
      { value: "cst_china", label: "China Standard Time (CST)" },
      { value: "jst", label: "Japan Standard Time (JST)" },
      { value: "kst", label: "Korea Standard Time (KST)" },
      { value: "ist_indonesia", label: "Indonesia Central Standard Time (WITA)" },
    ],
    "Australia & Pacific": [
      { value: "awst", label: "Australian Western Standard Time (AWST)" },
      { value: "acst", label: "Australian Central Standard Time (ACST)" },
      { value: "aest", label: "Australian Eastern Standard Time (AEST)" },
      { value: "nzst", label: "New Zealand Standard Time (NZST)" },
      { value: "fjt", label: "Fiji Time (FJT)" },
    ],
    "South America": [
      { value: "art", label: "Argentina Time (ART)" },
      { value: "bot", label: "Bolivia Time (BOT)" },
      { value: "brt", label: "Brasilia Time (BRT)" },
      { value: "clt", label: "Chile Standard Time (CLT)" },
    ],
  };
  
  export const items = [
    { id: "sunday", label: "Sunday" },
    { id: "monday", label: "Monday" },
    { id: "tuesday", label: "Tuesday" },
    { id: "wednesday", label: "Wednesday" },
    { id: "thursday", label: "Thursday" },
    { id: "friday", label: "Friday" },
    { id: "saturday", label: "Saturday" },
  ];
  interface OPTIONS {
    label: string;
    value: string;
    group: string;
    id: number;
  }
  export  const OPTIONS: OPTIONS[] = [
    { label: "User1", value: "user1", group: "User", id:1 },
    { label: "User2", value: "user2", group: "User", id:2 },
    { label: "Channel1", value: "channel1", group: "Channel", id:3 },
    { label: "Channel2", value: "channel2", group: "Channel", id:4 },
  ];

  export const USERS = [
    { label: "User1", value: "user1", group: "User", id:1 },
    { label: "User2", value: "user2", group: "User", id:2 },
  ];

  export const CHANNELS = [
    { label: "Channel1", value: "channel1", group: "Channel", id:3 },
    { label: "Channel2", value: "channel2", group: "Channel", id:4 },
  ];
  
 