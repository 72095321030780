import React from "react";
import { Link } from "react-router-dom";
import Accounts from "./Accounts";
import Company from "./Company";
import Members from "./Members";

export default function Settings() {
  return (
    <div className={`flex flex-row w-fit h-full`}>
      <nav
        className={`flex flex-col justify-start p-4   border-r border-gray-300 bg-[#f4f4f4]  `}
      >
        <ul>
          <li
            className={`text-md  text-gray-900 text-left py-4 ${
              location.pathname === "/settings/account" ? " font-bold" : ""
            }`}
          >
            <Link to="/settings/account">My Account</Link>
          </li>
          <li
            className={`text-md  text-gray-900 text-left py-4 ${
              location.pathname === "/settings/company" ? " font-bold" : ""
            }`}
          >
            <Link to="/settings/company">Company settings</Link>
          </li>
          <li
            className={`text-md  text-gray-900 text-left py-4 ${
              location.pathname === "/settings/members" ? " font-bold" : ""
            }`}
          >
            <Link to="/settings/members">Members</Link>
          </li>
        </ul>
      </nav>
      <div>
        {location.pathname === "/settings/account" ? (
          <Accounts />
        ) : location.pathname === "/settings/company" ? (
          <Company />
        ) : location.pathname === "/settings/members" ? (
          <Members />
        ) : (
          <div>404</div>
        )}
      </div>
    </div>
  );
}
