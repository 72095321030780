import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";
import CreateTrigger from "./CreateTriggerPreview";
import { LightningBoltIcon } from "@radix-ui/react-icons";
import { Trigger } from "./types";
import { useState } from "react";
import AvailableTriggers from "./AvailableTriggers";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface TriggerModalProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  className?: string;
  props?: any;
  triggers: any[];
}

export default function TriggerModal({
  isOpen,
  onOpenChange,
  className,
  props,
}: TriggerModalProps) {
  // const { setTrigger } = useTrigger();
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  // const handleTriggers = (trigger: Trigger) => {
  //   setTrigger(trigger);
  //   if (setIsModalOpen) {
  //     console.log("Setting isModalOpen to false");
  //     setIsModalOpen(false);
  //   }
  // };
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      {/* <DialogTrigger>Open</DialogTrigger> */}
      <DialogContent>
        <DialogHeader>
          {/* <DialogTitle>Available Triggers</DialogTitle> */}
          <DialogDescription>
            <div className=" rounded-sm p-2 mb-1">
              <AvailableTriggers
                className={className}
                // props={props}
                onClose={() => onOpenChange(false)}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
