"use client";

import * as React from "react";
import { Button } from "./components/ui/button";
import { cn } from "@/lib/utils";
import { useNavigate } from "react-router-dom";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function UserAuthForm({
  className,
  ...props
}: UserAuthFormProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const slackLogoUrl =
    "https://cdn-icons-png.flaticon.com/512/5968/5968929.png"; // Direct Slack logo URL

  const navigate = useNavigate();

  const openDashboard = () => {
  navigate("/home");
  };

  return (
    <div className={`grid grid-cols-1 md:grid-cols-2 h-screen m-0 p-0`}>
      {/* Left Section */}
      <div className="bg-[#2c2c2c] h-[100%] flex flex-col  justify-between">
        <h1 className="text-white text-left text-lg font-bold p-4 flex items-center">
          <img
            src="/logo-v2.png"
            alt="Workfolio Logo"
            className="w-[80px] h-auto p-4"
          />
          Workfolio Bot
        </h1>
        <div className="text-white text-left p-6  sm:w-[100%]   ">
          <p className="text-md">
            Workfolio Bot has transformed our workflow-automating standups,
            reminders, and surveys keeps our team organized and focused. A
            must-have for any team aiming to streamline daily tasks!
          </p>
          <p className="text-xs py-2">
            <b>Jordan Mitchell,</b> Operations Manager
          </p>
        </div>
      </div>

      {/* Right Section */}
      <div
        className={cn(
          "flex flex-col items-center justify-center bg-white",
          className
        )}
        {...props}
      >
        <img
          src="/logo-v2.png"
          alt="Workfolio Logo"
          className="w-[30%] h-auto p-4"
        />
        <h1 className="text-black text-xl font-bold p-4">
          Login with your slack account
        </h1>
        <Button
          variant="outline"
          type="button"
          disabled={isLoading}
          className="w-[300px] h-[40px] rounded-sm flex items-center justify-center gap-2"
          onClick={() => {
            openDashboard();
          }}
        >
          <img
            src={slackLogoUrl}
            alt="Slack Logo"
            className="w-5 h-5"
            loading="lazy"
          />
          Slack
        </Button>
        <p className="w-[300px] text-[#888] text-xs p-2 text-center">
          By clicking continue, you agree to our{" "}
          <a className="underline cursor-pointer">Terms of Service</a> and{" "}
          <a className="underline cursor-pointer">Privacy Policy</a>.
        </p>
      </div>
    </div>
  );
}
