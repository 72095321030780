// AvailableTriggers.js
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { useTrigger } from "./triggerContext";
import { triggers } from "./myTriggers";
import { useNavigate } from "react-router-dom";
import "./trigger.css";

interface AvailableTriggersProps {
  className?: string;
  onClose: () => void;
  setIsModalOpen: (open: boolean) => void;
}

export default function AvailableTriggers({
  className,
  onClose,
  setIsModalOpen,
}: AvailableTriggersProps) {
  const { setTrigger } = useTrigger();
  const navigate = useNavigate();

  const handleTriggerClick = (trigger: any) => {
    // console.log("trigger clicked", trigger);

    setTrigger(trigger);
    // if (setIsModalOpen) {
    onClose();
    // }
    navigate(`/${trigger.trigger_hash}-preview`);
  };

  return (
    <div className="rounded-sm p-4 pb-1 mb-4">
      <h1 className="text-xl font-medium text-left text-slate-900 flex items-center">
        <span className="text-[#266DFB] font-bold mr-2 text-2xl">💡</span>
        Available Templates {`(${triggers.length})`}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2 my-4">
        {triggers.map((trigger, index) => (
          <Card
            key={index}
            className={cn(
              "cursor-pointer flex flex-row justify-center w-full h-[100px] hover:shadow-lg overflow-hidden transition-all duration-100 trigger-card",
              className
            )}
            onClick={() => handleTriggerClick(trigger)}
          >
            <div className="flex items-center justify-center text-[#e2bb50] text-[30px] lg:text-4xl md:text-[30px] sm:text-[30px] ms-3 trigger-icon">
              {trigger.trigger_icon}
            </div>
            <CardHeader className="relative">
              <CardTitle className="flex flex-start text-left">
                {trigger.trigger_name}
              </CardTitle>
              <CardDescription className="flex flex-start text-left">
                {trigger.trigger_desc}
              </CardDescription>
            </CardHeader>
          </Card>
        ))}
      </div>
    </div>
  );
}
