export interface TriggerDetails {
  trigger_name: string;
  trigger_participants: string[];
  trigger_recipients?: string[];
  trigger_desc?: string;
  trigger_period?: string;
  trigger_days?: string[];
  trigger_type: string;
  trigger_icon: string;
  trigger_time: {
    time: string;
    timezone: string;
  };
}

import {serverUrl} from '../types';

async function createTriggerApi(triggerDetails: TriggerDetails): Promise<any> {
  try {
    const response = await fetch(`http://localhost:3000/api/triggers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(triggerDetails),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Data received:', data);
    return data;
  } catch (error: any) {
    console.error('Error in createTriggerApi:', error.message);
    throw error;
  }
}

export default createTriggerApi;
